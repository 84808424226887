import React from 'react';
import { Container, Row, Col } from 'src/interface/basics/grids';
import { Section, SectionHeader, SectionPretitle, SectionTitle, SectionText, SectionBody } from 'src/interface/basics/sections';

const AboutSectionStory = ({ className = '' }) => {

  return (
    <Section className={`py-8 ${className}`}>
      <Container>
        <Row>
          <Col md={4}>
            <SectionPretitle>About us</SectionPretitle>
            <SectionTitle>Building a Better Future</SectionTitle>
          </Col>
          <Col md={8}>
            <SectionBody className='mb-2'>
              <h5 className='gray-700 text'>
                The world is changing and software is leading the way.
                For consumers, software is changing the way we interact with the world and each other.
                In business, software is leveling the playing field and, in some cases, re-defining the playing field itself.
              </h5>
              <h5 className='gray-700 text'>
                At Mile Hi Labs, our team honed our skills working on startups where we learned how to design, build, and scale platforms in competitive markets.
                Prior to Mile Hi Labs, we built companies from the ground up powering over $1M dollars in transactions.
                Since then, we've built open-source libraries and client-based platforms that collectively serve thousands of customers.
              </h5>
              <h5 className='gray-700 text'>
                Regardless of your industry, every business needs a partner that's fluent in business, software, and technology.
                That way, you're always prepared for the unknown while forging ahead at competitive rate.
              </h5>
              <h5 className='gray-800 title'>
                Eric Hubbell
              </h5>
              <h6 className='gray'>Founder @ Mile Hi Labs</h6>
            </SectionBody>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

export default AboutSectionStory;
