import React from 'react';
import ListArticle from 'src/interface/articles/list-article';
import { Container, Row, Col } from 'src/interface/basics/grids';
import { Section, SectionHeader, SectionPretitle, SectionTitle, SectionText, SectionBody } from 'src/interface/basics/sections';

const AboutSectionValues = ({ className = '' }) => {
  return (
    <Section className={`py-8 ${className}`}>
      <Container>
        <Row>
          <Col md={4}>
            <SectionTitle>Our Guiding Principles</SectionTitle>
          </Col>
          <Col md={8}>
            <SectionBody className='mb-2'>
            	<Row>
                <Col sm={6}>
                  <ListArticle
                    icon='thumbs-up'
                    title='Customer First'
                    description="Nothing else matters if we aren't listening to our customers and delivering on their needs."
                  />
                </Col>
                <Col sm={6}>
                  <ListArticle
                    icon='gift'
                    title='Exceptional Work'
                    description="Every project offers a new opportunity to produce our greatest work yet. Let's seize that opportunity."
                  />
                </Col>
            		<Col sm={6}>
            			<ListArticle
            				icon='thumbs-up'
            				title='Stay Genuine'
            				description="Relationships are everything so it's important to stay focused on what's best for the client."
          				/>
        				</Col>
            		<Col sm={6}>
            			<ListArticle
            				icon='stars'
            				title='Maintain Transparency'
            				description="If we believe in the job we're doing and how we're doing it, then we have nothing to hide."
          				/>
        				</Col>
            		<Col sm={6}>
            			<ListArticle
            				icon='handshake'
            				title='Reliability is key'
            				description="The nature of our business is already complex, so it's critical we remain a pillar that our partners can count on."
          				/>
        				</Col>
            		<Col sm={6}>
            			<ListArticle
            				icon='books'
            				title='Always Be Learning'
            				description="Learning and adapting is at the core of what we do. So, find time to ask questions and find answers responsibly."
          				/>
        				</Col>
      				</Row>
            </SectionBody>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

export default AboutSectionValues;
