import React from 'react';

import AboutHeader from 'src/components/about/about-header';
import AboutSectionStory from 'src/components/about/about-section-story';
import AboutSectionValues from 'src/components/about/about-section-values';
import AboutSectionTeam from 'src/components/about/about-section-team';

import MktRoute from 'src/interface/routes/mkt-route';
import MktNav from 'src/interface/navs/mkt-nav';
import MktPrefooter from 'src/interface/prefooters/mkt-prefooter';
import MktFooter from 'src/interface/footers/mkt-footer';

const AboutPage = () => {

  return (
    <MktRoute title='Mile Hi Labs | About us' description="Our team found their passion for technology working on startups where we learned how to design, build, and scale quickly and efficiently.">
      <MktNav />
      <AboutHeader />
      <main className='mvh-50'>
        <AboutSectionStory />
        <AboutSectionValues className='bg-gray-50' />
        {/*<AboutSectionTeam />*/}
        <MktPrefooter />
      </main>
      <MktFooter />
    </MktRoute>
  )
}

export default AboutPage;
